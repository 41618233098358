import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import logo from '../img/logo3.svg'

const Navbar = ({ navBright }) => {
  const [navBarActiveClass, setNavBarActiveClass] = useState('')

  const toggleHamburger = event => {
    // toggle the active boolean in the state
    const class_ = navBarActiveClass ? '' : 'is-active'
    setNavBarActiveClass(class_)
  }

  return (
    <nav
      className={`navbar${navBright || navBarActiveClass ? ' navBright' : ''}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="Kaldi" style={{ width: '120px' }} />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={toggleHamburger}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
          <div className="navbar-end has-text-centered">
            <Link className="navbar-item" to="/about">
              About
            </Link>
            <Link className="navbar-item" to="/projects">
              Projects
            </Link>
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  navBright: PropTypes.bool,
}

export default Navbar
